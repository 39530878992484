<template>
  <Modal
    :title="isEditing ? 'Update Office Permission' : 'Create Office Permission'"
    :show="show"
    :class="'modal-wfo-permission'"
    :submitLabel="isEditing ? 'Save' : 'Submit'"
    :loadingSubmit="loadingSubmit"
    :isCancel="false"
    :isSubmit="false"
    @hide="hide"
    @onSubmit="onSubmit"
  >
    <template #form>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form
          @submit.prevent="handleSubmit(submit)"
          class="is-label-14-px-normal form-timeoff-type"
          multipart
        >
          <div class="columns is-multiline">
            <div class="column is-12">
              <ValidationProvider
                name="Date"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Date"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-datepicker
                    v-model="formData.date"
                    placeholder="Pick Date"
                    icon-right="calendar"
                    :locale="locale"
                    :min-date="minDate"
                    editable
                    class="in-office-datepicker"
                    :disabled="isEditing && notUpdatable"
                  ></b-datepicker>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12 section-radio-tooltip">
              <ValidationProvider
                name="Type"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Permission Type"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                  class="radio-btn-in-office"
                >
                  <div>
                    <b-radio
                      v-model="formData.type"
                      native-value="1"
                      :disabled="isEditing && notUpdatable"
                    >
                      WFO
                    </b-radio>
                    <b-radio
                      v-model="formData.type"
                      native-value="2"
                      :disabled="isEditing && notUpdatable"
                    >
                      WFH
                    </b-radio>
                  </div>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12 section-radio-tooltip">
              <ValidationProvider
                name="Session"
                v-slot="{ errors }"
                rules="required"
              >
                <b-tooltip position="is-right" class="tooltip-date" multilined>
                  <b-icon
                    icon="help-circle"
                    size="is-small"
                    class="tooltip-date-icon"
                  ></b-icon>
                  <template v-slot:content>
                    <div class="is-flex">
                      <div class="time-detail">Morning</div>
                      <div>: 08.00 - 12.00</div>
                    </div>
                    <div class="is-flex">
                      <div class="time-detail">Afternoon</div>
                      <div>: 13.00 - 18.00</div>
                    </div>
                    <div class="is-flex">
                      <div class="time-detail">Fullday</div>
                      <div>: 08.00 - 18.00</div>
                    </div>
                  </template>
                </b-tooltip>
                <b-field
                  label="Session"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                  class="radio-btn-in-office"
                >
                  <div class="radio-button-container">
                    <b-radio
                      v-model="formData.shift"
                      native-value="1"
                      :disabled="isEditing && notUpdatable"
                    >
                      Morning
                    </b-radio>
                    <b-radio
                      v-model="formData.shift"
                      native-value="2"
                      :disabled="isEditing && notUpdatable"
                    >
                      Afternoon
                    </b-radio>
                    <b-radio
                      v-model="formData.shift"
                      native-value="3"
                      :disabled="isEditing && notUpdatable"
                    >
                      Fullday
                    </b-radio>
                  </div>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12 request-section-radio-tooltip">
              <div v-if="!isEditing">
                <ValidationProvider name="additional approver">
                  <PICTooltip />
                  <PIC
                    :isEditing="isEditing"
                    :listUser="listUser"
                    :isFetchingUser="isFetchingUser"
                    @getUser="getUserList"
                    @selectUser="selectUser"
                    @getMoreUser="getMoreUserList"
                  />
                </ValidationProvider>
              </div>
              <div v-else>
                <b-field label="PIC">
                  <p>
                    <b-icon icon="account" class="request-approver-icon mr-2" />
                    {{ selectedUser }}
                  </p>
                </b-field>
              </div>
            </div>
            <div class="column is-12">
              <ValidationProvider
                name="Purpose"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Purpose"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-input
                    v-model="formData.reason"
                    placeholder="Write down the purpose"
                    type="textarea"
                    :disabled="isEditing && notUpdatable"
                  ></b-input>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column">
              <b-button
                type="is-primary"
                expanded
                :loading="loadingSubmit"
                class="modal-submit-btn"
                :disabled="loadingSubmit || (isEditing && notUpdatable)"
                @click="onSubmit"
              >
                {{ isEditing ? 'Save' : 'Submit' }}
              </b-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </Modal>
</template>

<script>
// components
import Modal from '../../../components/Modals/Modal.vue'
import PICTooltip from '../../../components/PIC/PICTooltip.vue'
import PIC from '../../../components/PIC/PIC.vue'

export default {
  components: { Modal, PICTooltip, PIC },

  data() {
    return {}
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: null,
    },
    loadingSubmit: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    notUpdatable: {
      type: Boolean,
      default: false,
    },
    listUser: {
      type: Array,
      default: null,
    },
    isFetchingUser: {
      type: Boolean,
      default: false,
    },
    locale: {
      type: String,
      default: '',
    },
    minDate: {
      type: Date,
      default: null,
    },
    selectedUser: {
      type: String,
      default: null,
    },
  },

  methods: {
    /**
     * Emit hide modal component.
     */
    hide() {
      this.$emit('hide')
    },

    /**
     * Emit Submit modal.
     */
    onSubmit() {
      this.$emit('submit')
    },

    /**
     * Emit Submit modal.
     */
    getUserList(event) {
      this.$emit('getUserList', event)
    },

    /**
     * Emit select user.
     * @param event listener
     */
    selectUser(event) {
      this.$emit('selectUser', event)
    },

    /**
     * Emit Submit modal.
     */
    getMoreUserList() {
      this.$emit('getMoreUserList')
    },
  },
}
</script>
