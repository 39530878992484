<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page page-title="Office Permission">
      <template #page-header>
        <h2 class="page-title">Office Permission</h2>
        <r-menu-tab :level="3" />
      </template>
      <template #action-bar>
        <RequestHeader
          :search="true"
          @addNew="openModalCreate"
          :lodaData="isWFOPermissionLoading"
          @onSearch="searchIconClick($event)"
        ></RequestHeader>
      </template>

      <template #page-content>
        <div
          id="table-request-employment"
          class="table-timeoff-type table-request-personal"
        >
          <b-table
            :data="wfoPermissionRequestData"
            :per-page="perPage"
            :loading="isWFOPermissionLoading"
            @page-change="onPageChange"
            :show-detail-icon="showDetailIcon"
            ref:table
            hoverable
            narrowed
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            class="employment-directory-table general-table"
            @sort="onSort"
            :sticky-header="stickyHeaders"
          >
            <template>
              <b-table-column
                field="id"
                label="Request ID"
                v-slot="props"
                sortable
              >
                <span class="is-capitalize">
                  {{ props.row.id }}
                </span>
              </b-table-column>

              <b-table-column
                field="wfo_date"
                label="Date"
                v-slot="props"
                sortable
              >
                <span class="is-capitalize">
                  {{ formatDate(props.row.date) }}
                </span>
              </b-table-column>

              <b-table-column
                field="type"
                label="Type"
                v-slot="props"
                sortable
                cell-class="table-in-office-session"
              >
                <span :class="formatTypeClass(props.row.type)">
                  {{ formatType(props.row.type) }}
                </span>
              </b-table-column>

              <b-table-column
                field="wfo_shift"
                label="Session"
                v-slot="props"
                sortable
                cell-class="table-in-office-session"
              >
                <span :class="formatSessionClass(props.row.shift)">
                  {{ formatSession(props.row.shift) }}
                </span>
              </b-table-column>

              <b-table-column
                field="note"
                label="Purpose"
                v-slot="props"
                sortable
              >
                <span class="is-capitalize">
                  {{ props.row.reason || '-' }}
                </span>
              </b-table-column>

              <b-table-column
                field="status"
                label="Status"
                v-slot="props"
                sortable
              >
                <span
                  :class="`is-capitalize
                    ${determineFCByStatus(props.row.status)}`"
                >
                  {{ props.row.status }}
                </span>
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="created_at"
                label="Created At"
                sortable
                width="12%"
              >
                <span>
                  {{ formatDate(props.row.createdAt, 'DD MMMM YYYY') }}
                </span>
              </b-table-column>
              <b-table-column
                field="action"
                label="Action"
                v-slot="props"
                centered
              >
                <!-- Edit icon for next sprint. Don't delete!!! -->
                <b-icon
                  icon="pencil"
                  custom-size="mdi-18px"
                  class="click"
                  @click.native="
                    openModalCreate(wfoPermissionRequestData[props.index])
                  "
                  v-if="props.row.status === 'waiting'"
                ></b-icon>
                <b-icon
                  icon="eye"
                  custom-size="mdi-18px"
                  class="click"
                  @click.native="openSidebar(props.row)"
                ></b-icon>
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>No data found</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
        <div class="has-text-centered" v-if="isLoadMore">Please Wait ...</div>

        <!-- Modal to create new request -->
        <WFOPermissionPersonalModal
          :show="isModalCreateOpen"
          :formData="data"
          :loadingSubmit="isSubmitting"
          :isEditing="isEditing"
          :listUser="listUser"
          :isFetchingUser="isFetchingUser"
          :notUpdatable="notUpdatable"
          :locale="locale"
          :minDate="minDate"
          :selectedUser="selectedUser"
          @hide="closeModalCreate"
          @submit="submit"
          @getUserList="getUserList($event)"
          @selectUser="selectUser($event)"
          @getMoreUserList="getMoreUserList"
        />

        <b-sidebar
          v-if="isRightBarOpen && data != null"
          can-cancel
          fullheight
          :fullwidth="fullWidth"
          :overlay="true"
          right
          v-model="isRightBarOpen"
        >
          <div class="rightbar-detail-top-content-container">
            <div class="right-bar-detail-top-content">
              <p class="has-text-weight-black is-size-4 title">
                Office Permission Details
              </p>
              <b-icon
                icon="close"
                class="x-close-btn"
                custom-size="mdi-18px"
                @click.native="closeSidebar"
              ></b-icon>
              <img
                class="profile-picture"
                :src="determineProfilePicture(currentUser)"
              />

              <p class="has-text-weight-black is-size-4 full-name">
                {{ currentUser.firstName }} {{ currentUser.middleName }}
                {{ currentUser.lastName }}
              </p>
            </div>
            <div class="columns is-multiline p-4 is-size-6">
              <div class="column is-4">Date</div>
              <div class="column is-8 has-text-weight-bold">
                {{ formatDate(selectedRequest.date) }}
              </div>
              <div class="column is-4">Session</div>
              <div class="column is-8 has-text-weight-bold">
                {{ formatSession(selectedRequest.shift) }}
              </div>
              <div class="column is-4">Purpose</div>
              <div class="column is-8 has-text-weight-bold">
                {{ selectedRequest.reason }}
              </div>
              <div class="column is-4">Status</div>
              <div class="column is-8 has-text-weight-bold is-capitalize">
                {{ selectedRequest.status }}
              </div>
              <div class="column is-12">
                <TimeOffApprovalHistoryComponent
                  :approval-history="selectedRequest.history"
                />
              </div>
              <div
                class="column is-12"
                v-if="
                  selectedRequest.status === 'waiting' ||
                  selectedRequest.status === 'approved'
                "
              >
                <b-button
                  expanded
                  @click="updateStatusToCancel(selectedRequest.id)"
                >
                  Cancel Request
                </b-button>
              </div>
            </div>
          </div>
        </b-sidebar>
      </template>
    </r-page>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { showToast, determineFontColorByStatus } from '@/services/util'
import moment from 'moment-timezone'
import TimeOffApprovalHistoryComponent from '../TimeOff/TimeOffApprovalHistoryComponent.vue'
import isNotUpdatable from '../Script/isNotUpdatable'
import debounce from 'lodash/debounce'
import ApiService from '../../../services/common/api.service'
import RequestHeader from '../../../components/Header/RequestHeader.vue'
import WFOPermissionPersonalModal from './WFOPermissionPersonalModal.vue'

export default {
  components: {
    TimeOffApprovalHistoryComponent,
    RequestHeader,
    WFOPermissionPersonalModal,
  },
  computed: {
    ...mapGetters({
      wfoPermissionRequestData: 'wfoPermission/getWFOPermissionRequestData',
      currentUser: 'auth/currentUser',
      wfoPermissionDetail: 'wfoPermission/getWFOPermissionDetail',
    }),
  },
  data() {
    const today = new Date()
    return {
      stickyHeaders: true,

      isPaginated: false,
      perPage: 20,
      page: 0,
      lastPage: 0,

      defaultSortOrder: 'desc',
      sortField: 'created_at',
      sortOrder: 'desc',
      search: '',

      showDetailIcon: true,

      isWFOPermissionLoading: false,
      isLoadMore: false,

      isModalCreateOpen: false,
      data: {
        id: null,
        date: null,
        type: null,
        shift: null,
        reason: null,
        status: null,
        additionalApproverId: null,
      },
      locale: 'en-GB',
      minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      isSubmitting: null,

      selectedRequest: null,
      isModalDetailOpen: false,
      isUpdatingStatus: false,
      isEditing: false,
      notUpdatable: null,
      isRightBarOpen: false,
      fullWidth: false,
      right: false,

      selectedUser: null,
      listUser: [],
      isFetchingUser: false,
      pageUserList: 1,
      lastPageUserList: 1,
      searchUser: null,
    }
  },
  methods: {
    ...mapActions({
      actionLoadWFOPermissionRequestData:
        'wfoPermission/fetchWFOPermissionRequestData',
      saveWFOPermission: 'wfoPermission/saveWFOPermission',
      updateWFOPermission: 'wfoPermission/updateWFOPermission',
      cancelWFOPermissionRequest: 'wfoPermission/cancelWFOPermissionRequest',
      actionLoadWFOPermissionDetail: 'wfoPermission/fetchWFOPermissionDetail',
    }),

    ...mapMutations({
      setWFOPermissionRequestsData: 'wfoPermission/setWFOPermissionRequestData',
      setWFOPermissionDetail: 'wfoPermission/setWFOPermissionDetail',
    }),

    /**
     * Set table status color
     * @param {String} status - request status
     * @return determineFontColorByStatus method
     */
    determineFCByStatus(status) {
      return determineFontColorByStatus(status)
    },

    /**
     * Set Format Date
     * @param {dateString} str - date string
     * @param {dateString} format - format date (default: 'DD/MM/YYYY')
     * @return {dateString} formatted date
     */
    formatDate(str, format = 'DD/MM/YYYY') {
      if (str) {
        return moment(str).format(format)
      }
    },

    /**
     * Set WFO Session name
     * @param {integer} data - id session
     * @return {String} session name
     */
    formatSession(data) {
      if (data === 1) {
        return 'Morning'
      } else if (data === 2) {
        return 'Afternoon'
      } else if (data === 3) {
        return 'Full Day'
      }
    },

    /**
     * Set WFO Session css class
     * @param {integer} data - id session
     * @return {String} session class name
     */
    formatSessionClass(data) {
      if (data === 1) {
        return 'session-morning'
      } else if (data === 2) {
        return 'session-afternoon'
      } else if (data === 3) {
        return 'session-full-day'
      }
    },

    /**
     * Set WFO Type name
     * @param {String} data - wfo type name
     * @return {String} wfo type name
     */
    formatType(data) {
      data = parseInt(data)
      if (data === 1) {
        return 'WFO'
      } else if (data === 2) {
        return 'WFH'
      }
    },

    /**
     * Set WFO Type css class
     * @param {String} data - wfo type class
     * @return {String} wfo type name
     */
    formatTypeClass(data) {
      data = parseInt(data)
      if (data === 1) {
        return 'type-wfo'
      } else if (data === 2) {
        return 'type-wfh'
      }
    },

    /**
     * Load more data of office permission list
     */
    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadWFOPermission()
        this.isLoadMore = false
      }
    },

    /**
     * Search table data
     * @param {String} searchInput - keyword search
     */
    async searchIconClick(searchInput) {
      await this.resetState(searchInput)
    },

    /**
     * Reset state of table when form is created, updated
     * @param {String} searchInput - keyword search (optional)
     */
    async resetState(searchInput) {
      this.page = 0
      this.lastPage = 0
      this.setWFOPermissionRequestsData([])
      await this.loadWFOPermission(searchInput)
    },

    /**
     * Sort table
     * @param {String} field - name of field
     * @param {String} order - asc or desc
     */
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    /**
     * Change page behavior
     * @param {integer} page - number of page
     */
    onPageChange(page) {
      this.page = page
      this.loadWFOPermission()
    },

    /**
     * Load WFO Permission List data.
     * @param {String} searchInput - keyword search (optional)
     */
    async loadWFOPermission(searchInput) {
      this.isWFOPermissionLoading = true

      try {
        const response = await this.actionLoadWFOPermissionRequestData({
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          search: searchInput,
        })

        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isWFOPermissionLoading = false
    },

    /**
     * Open Request Modal. If row present,
     * set form to include data from selected table row
     * @param {integer} row - table row index (optional)
     */
    openModalCreate(row) {
      if (row) {
        this.isEditing = true
        this.data = {
          id: row.id,
          date: new Date(row.date),
          type: row.type,
          shift: row.shift,
          reason: row.reason,
          status: row.status,
        }
        this.selectedUser = row.history ? row.history[0].fullName : '-'
        this.notUpdatable = isNotUpdatable.check(row.status, row.history)
      }
      this.isModalCreateOpen = true
    },

    /**
     * Close request create/ edit modal
     */
    closeModalCreate() {
      this.resetForm()
      this.isModalCreateOpen = false
      this.isEditing = false
    },

    /**
     * Open Sidebar content from table
     * @param {Object} wfoPermission - WFO permission data
     * @param {integer} id - selected data id
     */
    async openSidebar(wfoPermission, id) {
      if (id) {
        let response = await this.actionLoadWFOPermissionDetail(id)
        this.selectedRequest = response.data.data
      } else {
        this.selectedRequest = wfoPermission
      }
      this.isRightBarOpen = true
    },

    /**
     * Determine Profile picture from database
     * @param {Array} user - user info that is currently logged in
     * @return {String} user profile picture url
     */
    determineProfilePicture(user) {
      if (!user.profilePictureUrl) {
        if (user.gender === 'M') {
          // male
          return '/images/default-profile-picture-male.png'
        }
        // female
        return '/images/default-profile-picture-female.png'
      }

      return user.profilePictureUrl
    },

    /**
     * Close Sidebar
     */
    closeSidebar() {
      this.isRightBarOpen = false
    },

    /**
     * Submit WFO Request
     */
    async submit() {
      this.isSubmitting = true
      let form = new FormData()

      try {
        form.append('date', moment(this.data.date).format('YYYY-MM-DD'))
        form.append('type', this.data.type)
        form.append('shift', this.data.shift)
        form.append('reason', this.data.reason || '')

        if (!this.isEditing) {
          form.append('additional_approver_id', this.data.additionalApproverId)
          if (!this.data.additionalApproverId) {
            form.delete('additional_approver_id')
          }

          await this.saveWFOPermission(form)
          showToast('Data is successfully added!', 'is-success', 'is-top')
        } else {
          const data = { id: this.data.id, form }

          await this.updateWFOPermission(data)
          showToast('Data is successfully updated!', 'is-success', 'is-top')
        }

        this.resetForm()
        this.isModalCreateOpen = false

        const response = await this.actionLoadWFOPermissionRequestData({
          perPage: this.perPage,
          page: this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          fetchType: 'fetch',
        })

        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (e) {
        showToast(e.response.data.message, 'is-danger', 'is-top')
      }

      this.isSubmitting = false
    },

    /**
     * Reset form when close/ hide
     */
    resetForm() {
      this.data = {
        date: null,
        type: null,
        shift: null,
        reason: null,
        additionalApproverId: null,
      }
    },

    /**
     * Select User from PIC database
     * @param {Object} option - selected user object (optional)
     */
    selectUser(option) {
      if (option) {
        this.selectedUser = option.fullName
        this.data.additionalApproverId = option.id
      }
    },

    /**
     * Get User list from database
     * @return {Object} listUser - User List
     */
    getUserList: debounce(function (name) {
      this.data.additionalApproverId = null
      if (this.selectedUser !== name) {
        this.selectedUser = name
        this.listUser = []
        this.pageUserList = 1
        this.lastPageUserList = 1
      }
      if (!name.length) {
        this.listUser = []
        this.pageUserList = 1
        this.lastPageUserList = 1
        return
      }
      this.isFetchingUser = true
      ApiService.get('/api/space-roketin/user/v3', {
        search: this.selectedUser,
        page: this.pageUserList,
      })
        .then((response) => {
          response.data.data.forEach((item) => this.listUser.push(item))
          this.pageUserList++
          this.lastPageUserList = response.data.meta.lastPage
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          this.isFetchingUser = false
        })
    }, 500),

    /**
     * Get more user list when auto complete scrolled
     * @return {Object} listUser - User List
     */
    getMoreUserList: debounce(function () {
      this.getUserList(this.selectedUser)
    }, 250),

    /**
     * Cancel WFO Personal request
     * @param {integer} id - selected data id
     */
    async updateStatusToCancel(id) {
      this.isUpdatingStatus = true
      let res = await this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'button is-primary',
          cancelButton: 'button',
        },
        text: `Are you sure? You can't undo this action.`,
      })

      if (res && res.isConfirmed) {
        try {
          await this.cancelWFOPermissionRequest(id)
          showToast('Request is successfully canceled!', 'is-success', 'is-top')
        } catch (err) {
          if (err.response.data.message) {
            showToast(err.response.data.message, 'is-danger', 'is-top')
          }
          console.log(err)
        }
      }

      this.isUpdatingStatus = false
      this.closeSidebar()
    },
  },

  async mounted() {
    this.setWFOPermissionRequestsData([])
    await this.loadWFOPermission()
    if (this.$route.query.id) {
      await this.openSidebar(null, this.$route.query.id)
    }
  },
}
</script>
